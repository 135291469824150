/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.cursor-pointer, .clickable {cursor: pointer;}
.mat-progress-spinner {margin: 10px auto;}
.mat-full-width { width: 100%; }
*::-webkit-scrollbar {
  width: 0.2em;
}

.mat-drawer:hover::-webkit-scrollbar {
  width: .8em;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0.5px solid slategrey;
}

table {
  margin-bottom: 0;
  border-bottom: 3px solid #e8e8e8;
}

tr th,
thead th,
th.mat-header-cell {
  border-top: 1px solid #ddd;
  background-color: #eeeeee;
  vertical-align: middle !important;
  font-weight: bold;
  color: #000000;
  padding: 8px !important;
}

th.check-all {
  width: 3em;
  padding-left: .65em;
}

.mat-column-select {
  flex: 0 0 60px;
  padding-left: 20px !important;
}

th.link,
.clickable {
  cursor: pointer;
}

tr.mat-header-row {
  height: auto !important;
  vertical-align: middle;
}

tr {
  vertical-align: middle;
}

tr.highlighted,
mat-row.highlighted {
  background-color: #afafaf !important;
  color: white;
}

tr.highlighted td,
mat-row.highlighted mat-cell {
  color: white;
}

tr td.mat-cell {
  vertical-align: middle !important;
}

.mat-cell {
  padding: 5px;
  font-size: 13px !important;
}

.search-box {
  margin-top: 1em;
  padding: inherit;
}

.pagination-row {
  margin: 20px 0;
}

.pagination-row ul {
  padding: 0;
  margin: 0;
}

.table-controls {
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.table-controls ul {
  padding: 0px;
  margin: 0 -15px;
}

.table-controls ul li {
  display: inline-block;
  font-size: 13px;
  border-right: 1px solid #E8E8E8;
  padding: 10px;
  margin-left: -4px;
}

.top-margin {
  margin-top: 2em;
}

.margin-auto {
  margin: 0 auto;
}

.disabled {
  opacity: 0.5;
  color: gray;
  cursor: not-allowed;
  pointer-events: none;
}
