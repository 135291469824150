@use '~@angular/material' as mat;
@import '~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';
@include mat.core();

$custom-typography: mat.define-typography-config($font-family: '"Libre Franklin", sans-serif;');
@include mat.core($custom-typography);
$custom-primary: mat.define-palette(mat.$deep-purple-palette);
$custom-accent: mat.define-palette(mat.$deep-purple-palette, 300);
$custom-warn: mat.define-palette(mat.$red-palette);
$custom-info: mat.define-palette(mat.$purple-palette, 400);
$custom-highlight: mat.define-palette(mat.$blue-gray-palette, 50);
$custom-theme: mat.define-light-theme($custom-primary, $custom-accent, $custom-warn);
// Export theme colors
$primary: mat.get-color-from-palette($custom-primary);
$accent: mat.get-color-from-palette($custom-accent);
$warn: mat.get-color-from-palette($custom-warn);
//
@include mat.all-component-themes($custom-theme);
@include mat-datetimepicker-theme($custom-theme);

a.mat-list-item.active {
  color: #fff !important;
  background: linear-gradient(14deg, mat.get-color-from-palette($custom-primary) 0%, mat.get-color-from-palette($custom-primary)) !important;
  -webkit-box-shadow: 1px 1px 5px 0 mat.get-color-from-palette($custom-primary) !important;
  box-shadow: 1px 1px 20px 0 mat.get-color-from-palette($custom-primary);
  // border-radius: 4px;
}

.main-menu-items:hover {
  font-weight: bold !important;
  color: mat.get-color-from-palette($custom-primary) !important;
}

a.mat-list-item.active:hover {
  color: mat.get-color-from-palette($custom-primary) !important;
  // background: #fff !important;
}

.mat-tab-label-active {
  color: #fff !important;
  background-color: mat.get-color-from-palette($custom-primary) !important;
  opacity: 1 !important;
  // border-radius: 4px;
}

.icon-holder {
  background-color: mat.get-color-from-palette($custom-primary) !important;
}

.mat-tab-link:not(.mat-tab-label-active):focus {
  // color: #fff !important;
  background: none !important;
  // border-radius: 4px;
}

::ng-deep.mat-tab-label.mat-tab-label-active {
  opacity: 1;
}

.theme-border-color {
  border-color: mat.get-color-from-palette($custom-primary) !important;
}

.mat-background-primary {
  background-color: mat.get-color-from-palette($custom-primary);
}

.mat-background-accent {
  background-color: mat.get-color-from-palette($custom-accent);
}

// .mat-background-info {
//   background-color: mat-color($custom-info);
// }

.mat-higlight {
  background-color: mat.get-color-from-palette($custom-highlight);
}

.mat-background-warn {
  background-color: mat.get-color-from-palette($custom-warn);
}

a {
  color: mat.get-color-from-palette($custom-primary);
}

footer a,
.highlighted a {
  color: mat.get-color-from-palette($custom-primary) !important;
}

@include mat.all-component-themes($custom-theme);
@include mat-datetimepicker-theme($custom-theme);
